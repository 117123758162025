.CountryDropdown {
  width: 100%;
}

.CountryDropdown-item {
  display: flex;
  align-items: center;
}

.CountryDropdown-item__image {
  width: 1.5em;
  margin-right: 1em;
}
