.three-pane-layout,
.three-pane-layout .grid {
  width: 100%;
  height: 100%;
  margin: 0;
}

.three-pane-layout .grid-main,
.three-pane-layout .grid-side {
  height: 100%;
}

.three-pane-layout .grid-main {
  display: flex;
  flex-direction: column; 
}

.three-pane-layout .grid-main__top {
  max-height: 50%;
  width: 100%;
  padding-bottom: 20px;
  flex-shrink: 0;
}

.three-pane-layout .grid-main__bottom {
  height: 100%;
  width: 100%;
  flex-shrink: 1;
  overflow: hidden;
}

.three-pane-layout .grid-main__top.fixedHeight,
.three-pane-layout .grid-main__bottom.fixedHeight {
  height: 50%;
  overflow: visible;
}
