.InputField {
  position: relative;
}

.InputField-validation {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  font-weight: bold;
}

.InputField-error {
  display: flex;
  align-items: center;
}

.InputField-error svg {
  margin-left: 4px;
}
