.login {
  height: 100%;
  padding: 50px;
}

.login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-content {
  width: 100%;
  max-width: 500px;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}

.login-content__header {
  font-family: 'Teko', sans-serif;
  font-size: 34px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login-content__header img {
  height: 60px;
  margin-right: 15px;
}

.login-content__form {
  width: 100%;
  padding: 50px 50px 33px;
}

.login-content__form h1 {
  margin-bottom: 20px;
}

.login-form {
  margin-top: 30px;
}

.login-content__description {
  display: none;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  overflow: hidden;
}

.login-content__description-wrapper { 
  position: relative;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-content__description-cards {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.login-content__description-cards__card {
  position: relative;
  width: 34%;
  padding-bottom: 46%;
  border-radius: 8px;
  box-shadow: 0 5px 10px 2px rgba(0,0,0,0.20);
  font-weight: bold;
  font-size: 16px;
}

.login-content__description-cards__card-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-content__description-cards__card img {
  width: 70%;
  border-radius: 50%;
  margin: 21% 0 9%;
}

.login-content__description-cards__card.leftCard {
  transform: rotate(-12deg);
}

.login-content__description-cards__card.rightCard {
  transform: rotate(12deg);
}

.login-content__description-cards__card.topCard {
  width: 44%;
  padding-bottom: 59%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-content__description-subtitle1 {
  margin: 40px 0 20px;
  font-size: 28px;
  font-weight: bold;
  
}
.login-content__description-subtitle2 {
  font-size: 14px;
}
.login-content__description-square {
  position: absolute;
  border: 1px solid;
  opacity: .1;
}
.login-content__description-bar {
  position: absolute;
  opacity: .1;
  border-radius: 8px;
}


.login-content__description-square.topLeft {
  transform: rotate(27deg);
  top: 38px;
  left: 64px;
  height: 134px;
  width: 100px;
  border-radius: 8px;
}

.login-content__description-square.topRight {
  transform: rotate(10deg);
  top: 74px;
  right: 62px;
  height: 50px;
  width: 66px;
  border-radius: 20px;
}

.login-content__description-square.bottomLeft {
  transform: rotate(120deg);
  bottom: 61px;
  left: 66px;
  height: 50px;
  width: 66px;
  border-radius: 20px;
}

.login-content__description-square.bottomRight {
  transform: rotate(-24deg);
  bottom: 54px;
  right: 0;
  height: 88px;
  width: 66px;
  border-radius: 8px;
}
.login-content__description-bar.bottomBar {
  transform: rotate(30deg);
  bottom: -4px;
  left: -29px;
  height: 50px;
  width: 222px;
}
.login-content__description-bar.topBar {
  transform: rotate(-330deg);
  top: 10px;
  right: -49px;
  height: 50px;
  width: 222px;
}
.login-footer {
  margin-top: 30px;
}

.login-footer__register {
  margin-bottom: 10px;
  font-weight: bold;
}

.login-footer__language {
  margin-top: 20px;
}

.login-content__connectionStatus {
  text-align: center;
  margin: 20px 0;
  padding: 20px;
  font-weight: bold;
}
