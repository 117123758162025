.RequestPasswordResetForm {
  width: 100%;
  padding-bottom: 15px;
}

.RequestPasswordResetForm-item {
  margin-bottom: 20px;
}

.RequestPasswordResetForm-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.RequestPasswordResetForm-submit {
  width: 100%;
}

.selectedHost {
  margin-top: 40px;
}
