.KnownHosts {
}

.KnownHosts-form {
  width: 100%;
  position: relative;
}

.KnownHosts-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.KnownHosts-item__wrapper {
  display: flex;
  align-items: center;
}

.KnownHosts-item__label {
  position: relative;
}

.KnownHosts-item__label svg {
  display: none;
  position: absolute;
  left: -.1em;
  top: 50%;
  transform: translate(-100%, -50%);
  font-size: .9em;
}

.KnownHosts-item__status {
  display: none;
}

.KnownHosts-item__status svg {
  display: none;
  margin-left: -5px;
  margin-right: 5px;
}

.KnownHosts-validation {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  font-weight: bold;
}

.KnownHosts-error {
  display: flex;
  align-items: center;
}

.KnownHosts-error svg {
  margin-left: 4px;
}

.KnownHosts .MuiSelect-selectMenu .KnownHosts-item__status,
.KnownHosts .MuiSelect-selectMenu .KnownHosts-item__status svg {
  display: block;
}

.Mui-selected .KnownHosts-item__label svg {
  display: block;
}

.MuiSelect-selectMenu .KnownHosts-item__edit {
  display: none;
}
