.server,
.server-rooms,
.server-rooms__side {
  height: 100%;
}

.server {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.serverRoomWrapper {
  height: 100%;
}

.serverMessage {
  height: 100%;
  padding: 20px;
  margin-bottom: 2px;
}

.server-rooms {
  width: 100%;
}

.server-rooms__side-label {
  position: sticky;
  top: 0;
  padding: 10px;
  background: white;
  z-index: 1;
}
