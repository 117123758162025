.moderator-logs {
  height: 100%;
  display: flex;
  padding: 20px;
}

.moderator-logs__form {
  width: 40%;
  margin-right: 20px;
}

.moderator-logs__results {
  width: 100%;
}