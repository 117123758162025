.ResetPasswordForm {
  width: 100%;
  padding-bottom: 15px;
}

.ResetPasswordForm-item {
  margin-bottom: 20px;
}

.ResetPasswordForm-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.ResetPasswordForm-submit {
  width: 100%;
}
