.LanguageDropdown {
}

.LanguageDropdown-item {
  display: flex;
  align-items: center;
}

.LanguageDropdown-item__image {
  width: 1.5em;
}

.MuiSelect-root .LanguageDropdown-item__label {
  display: none;
}

.MuiListItem-root .LanguageDropdown-item__image {
  margin-right: 1em;
}
