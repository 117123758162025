.Initialize {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Initialize img {
  width: 60px;
}

h6.subtitle {
  margin: 20px 0 10px;
}

.Initialize-graphics {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Initialize-graphics__square {
  position: absolute;
  border: 2px solid;
  opacity: .05;
}

.Initialize-graphics__bar {
  position: absolute;
  opacity: .05;
  border-radius: 8px;
}

.Initialize-graphics__square.topLeft {
  transform: rotate(27deg);
  top: 38px;
  left: 64px;
  height: 134px;
  width: 100px;
  border-radius: 8px;
}

.Initialize-graphics__square.topRight {
  transform: rotate(10deg);
  top: 74px;
  right: 62px;
  height: 50px;
  width: 66px;
  border-radius: 20px;
}

.Initialize-graphics__square.bottomLeft {
  transform: rotate(120deg);
  bottom: 61px;
  left: 66px;
  height: 50px;
  width: 66px;
  border-radius: 20px;
}

.Initialize-graphics__square.bottomRight {
  transform: rotate(-24deg);
  bottom: 54px;
  right: 0;
  height: 88px;
  width: 66px;
  border-radius: 8px;
}

.Initialize-graphics__bar.bottomBar {
  transform: rotate(30deg);
  bottom: -4px;
  left: -29px;
  height: 50px;
  width: 222px;
}

.Initialize-graphics__bar.topBar {
  transform: rotate(-330deg);
  top: 10px;
  right: -49px;
  height: 50px;
  width: 222px;
}
