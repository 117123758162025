.user-display,
.user-display__link {
  height: 100%;
  width: 100%;
}

.user-display__details {
  height: 100%;
  display: flex;
  align-items: center;
}

.user-display__country {
  width: 1.1em;
  margin-right: 0.4em;
}
