.AccountActivationForm {
  width: 100%;
  padding-bottom: 15px;
}

.AccountActivationForm-item {
  margin-bottom: 20px;
}

.AccountActivationForm-submit {
  width: 100%;
}
