.RegisterForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.RegisterForm-column {
  width: 48%;
}

.RegisterForm-item {
  margin-bottom: 20px;
}

.RegisterForm-submit {
  width: 100%;
}
