.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-content {
  width: 700px;
  max-width: 100%;
}
